import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'core/axios';
import CertificateQrReader from '../issuedCertificates/parts/CertificateQrReader';
import { parsePersonalCode, createPersonHash } from '../utils/personalCode';
import { hasCryptoSupport, decrypt } from '../utils/encryption';


const CheckImmunityForm = ({ setCertificates, setCertificatesFetched, resultRef }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { searchParams } = new URL(window.location.href);

  const handleSubmitFinished = (certificates) => {
    setCertificates(certificates);
    setCertificatesFetched(true);
    setIsLoading(false);
    resultRef.current && resultRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  // @todo refactor, separate QR handling and API call
  const handleQrScan = (personalCode) => {
    setIsLoading(true);
    setCertificatesFetched(false);
    if (!personalCode) {
      handleSubmitFinished([]);
      return;
    }
    const [passportId, pepper] = parsePersonalCode(personalCode);
    if (!passportId) {
      handleSubmitFinished([]);
      return;
    }
    const personHash = createPersonHash(passportId, pepper);
    axios.get(`/api/v1/certificates/check/${personHash}/`)
      .then(
        ({ data }) => {
          if (!{ data }.length) {
            handleSubmitFinished([]);
            return;
          }
          const certificatesPromises = data.map(
            (item) => {
              const certificate = {
                ownerName: item.owner_name,
                issuerName: item.issuer_name,
                issuerIds: item.issuer_ids,
                testKitId: item.test_kit_id,
                extraData: item.extra_data,
                encryptedData: '',
                expiryDate: new Date(item.expiry_timestamp * 1000),
                sampleDate: new Date(item.sample_timestamp * 1000),
                expired: item.expiry_timestamp < Math.floor(Date.now() / 1000),
                revoked: item.revoked,
                description: item.description,
                passportId,
              };
              if (!item.encrypted_data || !hasCryptoSupport) {
                return certificate;
              }
              return decrypt(item.encrypted_data, pepper).then(
                (decryptedData) => ({ ...certificate, encryptedData: decryptedData }),
              );
            },
          );
          Promise.all(certificatesPromises).then((certificates) => handleSubmitFinished(certificates));
        },
      )
      .catch((_error) => { handleSubmitFinished([]); });
  };

  useEffect(() => {
    if (searchParams.get('id') && searchParams.get('pp')) {
      handleQrScan(window.location.href);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !searchParams.get('id') && <CertificateQrReader onScan={handleQrScan} disabled={isLoading} />;
};


CheckImmunityForm.propTypes = {
  setCertificates: PropTypes.func.isRequired,
  setCertificatesFetched: PropTypes.func.isRequired,
  resultRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]).isRequired,
};


export default CheckImmunityForm;
