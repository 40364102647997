/**
 * List of Issuer Registries.
 */


import { Formik, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { getApiUrl, getUrl } from 'utils/urls';
import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';
import { TextField, SubmitButton } from 'core/forms/fields';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import FormErrors from '../../components/common/formikErrors';


const CertificateContractCreate = () => {
  const onSubmit = (values, actions) => {
    axios.post(getApiUrl('/certificates/owned/'), { description: values.description })
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, getUrl('/certificates/types/'));
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Formik
      initialValues={{
        nonFieldErrors: '',
        description: '',
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        description: Yup.string()
          .max(256, gettext('Line is too long'))
          .required(gettext('Required')),
      })}
    >
      {({ isSubmitting }) => (
        <Form>
          <ErrorMessage component={FormErrors} name="nonFieldErrors" />
          <TextField name="description" label={gettext('Contract description:')} />
          <SubmitButton disabled={isSubmitting}>{gettext('Create')}</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};


export default CertificateContractCreate;
