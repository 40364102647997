/**
 * List of Owned Certificate Types.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'core/axios';
import List from '../../core/lists/list';


const OwnedCertificateContractsList = ({ companyId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [certificateTypesList, setCertificateTypesList] = useState([]);

  useEffect(() => {
    axios.get(`/api/v1/company/${companyId}/certificates/owned/`)
      .then(({ data }) => { setCertificateTypesList(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [companyId]);

  return (
    <List
      collection={certificateTypesList}
      isLoading={isLoading}
      loadingText={gettext('Loading certificates...')}
      emptyText={gettext('No certificates')}
      rowTitle={(item) => item.description}
      rowLink={(item) => `/company/${companyId}/certificates/types/${item.id}/`}
    />
  );
};


OwnedCertificateContractsList.defaultProps = {
  companyId: null,
};

OwnedCertificateContractsList.propTypes = {
  companyId: PropTypes.string,
};


export default OwnedCertificateContractsList;
