import PropTypes from 'prop-types';

import DescriptionList from './DescriptionList';
import CertificateQrReader from './CertificateQrReader';


const ScanIdentityComponent = ({ onScan, passportId, pepper }) => (
  <>
    <br />
    <CertificateQrReader onScan={onScan} />
    {passportId && pepper && (
      <>
        <hr />
        <DescriptionList
          className="dl-with-accent"
          data={[['Passport ID', passportId]]}
        />
      </>
    )}
  </>
);


ScanIdentityComponent.propTypes = {
  onScan: PropTypes.func.isRequired,
  passportId: PropTypes.string.isRequired,
  pepper: PropTypes.string.isRequired,
};


export default ScanIdentityComponent;
