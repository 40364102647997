/* eslint-disable react/no-string-refs */
import { Component } from 'react';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';

import { Button } from 'core/forms/fields';


class CertificateQrReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legacyMode: false,
      // eslint-disable-next-line react/no-unused-state
      result: 'No result',
    };
    this.handleScan = this.handleScan.bind(this);
    this.openImageDialog = this.openImageDialog.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleError(_e) {
    this.setState({ legacyMode: true });
  }

  handleScan(result) {
    const { onScan } = this.props;
    if (result) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ result });
      onScan(result);
    }
  }

  handleLoad() {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ loading: false });
  }

  openImageDialog() {
    this.refs.qrReader1.openImageDialog();
  }

  render() {
    const { disabled } = this.props;
    const { legacyMode } = this.state;
    const legacyModeStyle = legacyMode ? {
      display: 'none',
    } : {};

    return (
      <>
        <QrReader
          ref="qrReader1"
          className="qr-reader"
          onError={this.handleError}
          onScan={this.handleScan}
          legacyMode={legacyMode}
          style={legacyModeStyle}
          onLoad={this.handleLoad}
          delay={1000}
        />
        {legacyMode && (
          <Button onClick={this.openImageDialog} disabled={disabled}>
            {gettext('Scan QR Code')}
          </Button>
        )}
      </>
    );
  }
}

CertificateQrReader.defaultProps = {
  disabled: false,
};

CertificateQrReader.propTypes = {
  onScan: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};


export default CertificateQrReader;
