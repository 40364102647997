import { useEffect } from 'react';

import { PriorityActionsBar } from '@trustwise/design-system';
import { getGlobalContext } from 'core/globals';
import PageHeaderComponent from '../header';
import ActionsBarComponent from '../actions';
import AppNavigation from '../menu/navigation';
import MessagesList from '../messagesList';

export interface PageMainContentProps {
  /**
   * Left header content
   */
  headerLeft?: React.ReactElement;
  /**
   * Page heading
   */
  pageHeading: string;
  /**
   * Main content
   */
  children: React.ReactElement;
  /**
   * Messages to be displayed at the top of the page, e.g. general warnings and errors
   */
  pageMessages?: React.ReactNode;
  /**
   * Indicates whether the page should be rendered in fullscreen mode, e.g. when the whole view is in react
   */
  fullscreen?: boolean;
  /**
   * Actions to render at the actions bar
   */
  actions?: Array<Record<string, unknown>>;
  /**
   * @deprecated Currently used only for governance events detail view
   *
   * Indicates if the actions should be rendered with design system priority actions component
   */
  actionsMode?: 'actionsBar' | 'priorityActions';
  /**
   * Indicates whether padding should be removed
   */
  noPadding?: boolean;
}

const PageMainContent = ({
  headerLeft,
  pageHeading,
  actions,
  children,
  pageMessages,
  fullscreen,
  actionsMode = 'actionsBar',
  noPadding,
}: PageMainContentProps) => {
  const hasTitleAnimation = !!window.animatedPageTitle;
  const { activeEntity: { name: activeEntityName } } = getGlobalContext();
  document.title = pageHeading;

  if (!fullscreen) {
    document.body.classList.add('page-content-overlay-open');
  }

  useEffect(() => {
    const insertedPageTitle = document.querySelector('.app-container-nested .page-title');
    insertedPageTitle && hasTitleAnimation && window.animatedPageTitle?.(insertedPageTitle);
  }, [hasTitleAnimation]);

  // @todo avoid this workaround for old contacts select filed overlay
  useEffect(() => {
    const pageTitle = document.querySelector('.page-title');
    pageTitle && hasTitleAnimation && window.animatedPageTitle?.(pageTitle);
  }, [hasTitleAnimation]);

  return (
    <div className={`${fullscreen ? 'app-container' : 'app-container-nested'}`}>
      {!!fullscreen && <div className="app-navigation-wrapper"><AppNavigation /></div>}
      <PageHeaderComponent headerLeft={headerLeft} />
      <div className="main">
        <div className="intersection-observer" />
        <h1 className="page-title">{pageHeading}</h1>
        <span className="page-subtitle">{activeEntityName}</span>
        <div className={`page-content${noPadding ? ' not-padded' : ''}`}>
          <MessagesList pageMessages={pageMessages} />
          {actionsMode === 'actionsBar' ? (
            <ActionsBarComponent className="menu" actionsList={actions || []} />
          ) : <PriorityActionsBar items={actions} />}
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageMainContent;
