import { getGlobalContext } from 'core/globals';
import { icons } from 'core/icons';
import { AllowedCompanyActions, isCompanyActionAllowed } from 'core/allowedActions';
import { MenuItemsIds } from '../constants';
import { MenuItemProps } from '../types';

const {
  activeEntity: { isCompany },
} = getGlobalContext();

export const account: MenuItemProps = {
  id: MenuItemsIds.ACCOUNT,
  label: gettext('Account'),
  url: '/account/',
  icon: isCompany ? icons.settingsCompany : icons.settingsPersonal,
  activeIcon: isCompany ? icons.settingsCompanyActive : icons.settingsPersonalActive,
  isDefault: true,
};

export const dashboard: MenuItemProps = {
  id: MenuItemsIds.DASHBOARD,
  label: gettext('Home'),
  url: '/',
  icon: icons.dashboard,
  activeIcon: icons.dashboardActive,
  isDefault: true,
};

export const inbox: MenuItemProps = {
  id: MenuItemsIds.INBOX,
  label: gettext('Inbox'),
  url: '/inbox/',
  icon: icons.inbox,
  activeIcon: icons.inboxActive,
  isDefault: true,
};

export const events: MenuItemProps = {
  id: MenuItemsIds.EVENTS,
  label: gettext('Events'),
  url: '/meetings/',
  icon: icons.events,
  activeIcon: icons.eventsActive,
  isDefault: true,
};

export const assets: MenuItemProps = {
  id: MenuItemsIds.ASSETS,
  label: gettext('Assets'),
  url: '/assets/',
  icon: icons.investments,
  activeIcon: icons.investmentsActive,
  subitems: [
    {
      id: MenuItemsIds.ASSETS_EQUITY,
      label: gettext('Equity'),
      url: '/assets/equity/',
      isDefault: true,
    },
    {
      id: MenuItemsIds.ASSETS_ARTWORKS,
      label: gettext('Artworks'),
      url: '/assets/artworks/',
    },
    {
      id: MenuItemsIds.ASSETS_UTILITY_TOKENS,
      label: gettext('Utility'),
      url: '/assets/utility/',
    },
    {
      id: MenuItemsIds.ASSETS_PAYMENT_TOKENS,
      label: gettext('Cash'),
      url: '/assets/cash/',
    },
    {
      id: MenuItemsIds.ASSETS_TRANSFERS,
      label: gettext('Transfers'),
      url: '/transfers/',
      icon: icons.transfer,
      activeIcon: icons.transferActive,
      isDefault: true,
    },
  ],
};

export const artworksService: MenuItemProps = {
  id: MenuItemsIds.SERVICES_ARTWORKS,
  label: gettext('Artworks'),
  url: '/services/registered-objects/artworks/',
};

export const associationsMembership: MenuItemProps = {
  id: MenuItemsIds.MEMBERSHIPS_ASSOCIATIONS,
  label: gettext('Associations'),
  url: '/memberships/associations/',
};

export const documents: MenuItemProps = {
  id: MenuItemsIds.DOCUMENTS,
  label: gettext('Documents'),
  url: '/documents/',
  icon: icons.documents,
  activeIcon: icons.documentsActive,
  subitems: [
    {
      id: MenuItemsIds.DOCUMENTS_ARCHIVE,
      label: gettext('Archive'),
      url: '/documents-archive/',
      isDefault: true,
    },
    {
      id: MenuItemsIds.DOCUMENTS_SIGNING,
      label: gettext('Contracts'),
      url: '/documents-signing/',
      isHidden: isCompany && !isCompanyActionAllowed(AllowedCompanyActions.DOCUMENTS),
      isDefault: true,
    },
  ],
};

export const markets: MenuItemProps = {
  id: MenuItemsIds.MARKETS,
  label: gettext('Markets'),
  url: '/markets/',
  icon: icons.markets,
  activeIcon: icons.marketsActive,
  isDefault: true,
};
