
const getVerificationContext = ({ entityId, hasOwnerAction, isCompleted, accountStatus }) => {
  const label = gettext('View account');
  const contextByRole = {
    verifier: { path: `/verify/${entityId}/`, label: gettext('View entity') },
    account_owner: hasOwnerAction ? { path: '/identification/', label } : null,
    company_registrant: isCompleted && accountStatus === 'verified'
      ? { path: `/company/${entityId}/`, label }
      : { path: `/registration/company/${entityId}/`, label },
  };
  if (accountStatus === 'deleted') {
    delete contextByRole.company_registrant;
  }
  return contextByRole;
};

const getBoardMemberContext = (companyId, isMemberRemoved) => ({
  owner: { path: '/boardroom/members/', label: gettext('View members') },
  company: { path: '/boardroom/members/', label: gettext('View board members') },
  member: isMemberRemoved ? null : {
    path: `/memberships/boardroom/${companyId}/`,
    label: gettext('View board membership'),
  },
});

const getMembershipContext = (companyId, isMemberRemoved) => {
  const ownerCompanyAction = { path: '/members/', label: gettext('View members') };
  return {
    owner: ownerCompanyAction,
    company: ownerCompanyAction,
    member: isMemberRemoved ? null : {
      path: `/memberships/associations/${companyId}/`,
      label: gettext('View memberships'),
    },
  };
};

const getCompanySignatoryContext = (companyId, isSignatoryRemoved) => ({
  company: { path: '/signatories/', label: gettext('View signatories') },
  signatory: isSignatoryRemoved ? null : {
    path: `/company/${companyId}/`,
    label: gettext('View company'),
  },
});

const getDocumentContext = ({ documentId, label = gettext('View document') }) => {
  const action = {
    path: `/documents-signing/${documentId}/`,
    label,
  };
  return {
    initiator: action,
    signatory: action,
    owner: action,
    recipient: action,
  };
};

const getSubscriptionsContext = ({ topic, subscriptionId, relationshipId, isListFinalized }) => {
  const label = gettext('View subscription');
  const ownerUrlPart = `/liabilities/equity/subscriptions/${subscriptionId}/`;
  const subscriberUrl = relationshipId ? `/equity/subscriptions/${relationshipId}/` : '';
  switch (topic) {
    case 'token_subscription': {
      return {
        owner: { path: ownerUrlPart, label },
        potential_shareholder: { path: `/markets/private-equity/subscriptions/${subscriptionId}/`, label },
      };
    }
    case 'token_subscription_allotment': {
      return {
        owner: { path: `${ownerUrlPart}subscription-certificates/`, label },
        subscriber: { path: subscriberUrl, label },
      };
    }
    case 'token_subscription_finalization':
      return {
        owner: { path: `${ownerUrlPart}${isListFinalized ? '' : 'subscription-certificates/'}`, label },
        subscriber: { path: subscriberUrl, label },
      };
    case 'token_subscription_poa_update':
      return {
        subscriber: { path: subscriberUrl, label },
      };
    default:
      return {};
  }
};

const getConversionContext = (tokenId) => ({
  owner: { path: `/liabilities/equity/${tokenId}/`, label: gettext('View new token') },
});

const getAllotmentContext = ({ btrxId, portfolioId, tokenId }) => {
  let contextOwner = null;
  let contextShareholder = null;
  if (btrxId) {
    contextOwner = { path: `/transfers/${btrxId}/`, label: gettext('View allotment') };
    contextShareholder = contextOwner;
  } else if (portfolioId) {
    contextOwner = { path: `/liabilities/equity/${tokenId}/shareholders/`, label: gettext('View shareholders') };
    contextShareholder = {
      path: `/equity/${portfolioId}/${tokenId}/`,
      label: gettext('View asset'),
    };
  } else {
    contextOwner = { path: `/liabilities/equity/${tokenId}/distribute/`, label: gettext('View liability') };
  }
  return {
    owner: contextOwner,
    shareholder: contextShareholder,
  };
};

const getTokenContext = (tokenId, tokenType) => {
  let pathPart = '/liabilities/equity/';
  switch (tokenType) {
    case 'TPT':
      pathPart = '/payment-tokens/';
      break;
    case 'UT':
      pathPart = '/liabilities/utility/';
      break;
    default:
      break;
  }
  return {
    owner: {
      path: `${pathPart}${tokenId}/`,
      label: gettext('View token'),
    },
  };
};

const getSimpleTransferContext = ({ tokenId, portfolioId, isPaymentToken }) => {
  const action = {
    path: isPaymentToken ? '/assets/cash/' : `/equity/${portfolioId}/${tokenId}/`,
    label: gettext('View token'),
  };
  return {
    sender: action,
    recipient: action,
  };
};

const getTransferContext = ({ btrxId, senderPortfolioId, tokenId, companyId }) => {
  if (senderPortfolioId) {
    return {
      sender: {
        path: `/equity/${senderPortfolioId}/${tokenId}/`,
        label: gettext('View asset to transfer'),
      },
    };
  }
  const label = gettext('View transfer');
  const context = { path: `/transfers/${btrxId}/`, label };
  return {
    sender: context,
    recipient: context,
    escrow_agent: { path: `/arbiter/${btrxId}/`, label },
    board_member: {
      path: `/memberships/boardroom/${companyId}/transactions/${btrxId}/`,
      label,
    },
  };
};

const getBoReportContext = (btrxId) => ({
  recipient: {
    path: `/transfers/${btrxId}/`,
    label: gettext('View allotment'),
  },
});

const getDelegateContext = ({ portfolioId, relationshipId, isDelegated }) => ({
  owner: isDelegated ? null : {
    path: `/delegation/${portfolioId}/`,
    label: gettext('View delegates'),
  },
  delegate: {
    path: `/delegation/switch/${relationshipId}/`,
    label: gettext('View account'),
  },
  removed_delegate: null,
});

const getContactsContext = (requestId) => ({
  recipient: { path: `/account/contacts/requests/${requestId}/`, label: gettext('View request') },
  sender: { path: '/account/contacts/my-contacts/', label: gettext('View pending requests') },
});

const getGovernanceEventContext = ({ eventId, isVoting, visibilityId }) => {
  if (isVoting) {
    // is a placeholder
    // @todo update to open related agenda item modal on redirect
    return {};
  }
  const action = {
    path: `/meetings/${eventId}/`,
    label: gettext('View event'),
  };
  if (visibilityId) {
    action.queryArgs = { visibility_id: visibilityId };
  }
  return {
    owner: action,
    invitee: action,
    signatory: action,
    custodian: { path: '/custodian/', label: gettext('View accounts list') },
  };
};

const getInformationContext = (eventId) => {
  const context = {
    path: `/meetings/informations/${eventId}/`,
    label: gettext('View information'),
  };
  return { recipient: context, sender: context };
};

const getPotentialShareholdersContext = (whitelistId) => ({
  owner: {
    path: `/ownership/potential-shareholders/${whitelistId}/members/add/`,
    label: gettext('View potential shareholders'),
  },
});

const getCertificateIssuerContext = (registryId) => ({
  owner: {
    path: `/certificates/issuer-registries/${registryId}/`,
    label: gettext('View issuers'),
  },
});

const getCertificatesContext = (certificateId) => ({
  owner: {
    path: `/certificates/types/${certificateId}/`,
    label: gettext('View certificate type'),
  },
});

const getConsortiumMembershipContext = ({ memberId, isInvited, memberType }) => {
  const label = isInvited ? gettext('View invitation') : gettext('View application');
  const managementUrlPart = '/blockchain/management/';
  const membershipUrlPart = '/blockchain/membership/';
  switch (memberType) {
    case 'consortium_member': {
      if (isInvited) {
        return {
          owner: { path: `${managementUrlPart}consortium-members/invited/${memberId}/`, label },
          invitee: { path: `${membershipUrlPart}invitation/consortium_membership/apply/`, label },
        };
      }
      return {
        owner: {
          path: `${managementUrlPart}consortium-members/applied/${memberId}/`,
          label,
        },
        applicant: null,
      };
    }
    case 'validator': {
      if (isInvited) {
        return {
          owner: { path: `${managementUrlPart}validators/invited/${memberId}/`, label },
          invitee: { path: `${membershipUrlPart}invitation/validators/apply/`, label },
        };
      }
      return {
        owner: {
          path: `${managementUrlPart}validators/${memberId}/votes/`,
          label,
        },
        applicant: null,
      };
    }
    case 'peer_owner': {
      if (isInvited) {
        return {
          owner: { path: `${managementUrlPart}nodes/pending/${memberId}/details/`, label },
          invitee: { path: `${membershipUrlPart}nodes/add/`, label },
        };
      }
      return {
        owner: {
          path: `${managementUrlPart}nodes/pending/${memberId}/votes/`,
          label,
        },
        applicant: {
          path: `${membershipUrlPart}nodes/`,
          label: gettext('View nodes list'),
        },
      };
    }
    default:
      return {};
  }
};

const getCBDCContext = (tokenId) => ({
  owner: {
    path: `/payment-tokens/${tokenId}/banknotes/`,
    label: gettext('View banknotes'),
  },
});

const getValuationContext = ({ portfolioId, tokenId, timeSeriesId }) => (
  portfolioId ? {
    shareholder: {
      path: `/equity/${tokenId}/valuation/${timeSeriesId}/`,
      label: gettext('View valuation'),
    },
  } : {}
);

const getInvoiceContext = () => ({
  recipient: {
    path: '/account/',
    queryArgs: { active_tab: 'billing' },
    label: gettext('View invoice'),
  },
});

const getContractUpgradeContext = (contractType, isCompleted) => {
  let path;
  switch (contractType) {
    case 'board_members':
      path = '/boardroom/upgrade-contract/';
      break;
    default: {
      console.error(`Unexpected contract upgrade type: ${contractType}`);
      break;
    }
  }
  return !isCompleted && !!path ? {
    owner: {
      path,
      label: gettext('Upgrade contract'),
    },
  } : {};
};

const getUtilityTokenContext = ({ tokenId, portfolioId = null }) => {
  const label = gettext('View token');
  if (!portfolioId) {
    return {
      owner: {
        path: `/liabilities/utility/${tokenId}/`,
        label,
      },
    };
  }
  const action = {
    path: `/assets/utility/${portfolioId}/${tokenId}/`,
    label,
  };
  return {
    sender: action,
    recipient: action,
    token_holder: action,
  };
};

const getPermissionsRegistryContext = ({ registryId, memberId = null }) => {
  const registryPath = `/registries/${registryId}/members/`;
  if (!memberId) {
    return {
      manager: {
        path: registryPath,
        label: gettext('View registry'),
      },
    };
  }

  return {
    manager: {
      path: `${registryPath}${memberId}/`,
      label: gettext('View member'),
    },
  };
};

const getMarketsContext = ({ tokenId }) => {
  // @todo update when more marketplaces are added
  const context = {
    path: `/markets/artworks/${tokenId}/`,
    label: gettext('View listing'),
  };
  return {
    initiator: context,
    recipient: context,
  };
};

const getDataRoomContext = ({ dataRoomId, permissionType }) => {
  const roomLabel = gettext('View data room');
  const memberContext = permissionType !== 'verify' ? {
    path: `/data-rooms/${dataRoomId}/`,
    label: roomLabel,
  } : {
    path: '/services/data-verification/',
    label: gettext('View data verifications'),
  };

  return {
    member: memberContext,
    owner: {
      path: `/data-rooms/${dataRoomId}/`,
      label: roomLabel,
    },
  };
};

const getDataSetContext = ({ dataRoomId, dataSetId, showParticipantAction }) => {
  const label = gettext('View data set');
  const ownerReaderContext = {
    path: `/data-rooms/${dataRoomId}/data-sets/${dataSetId}/`,
    label,
  };

  return {
    owner: ownerReaderContext,
    verifier: showParticipantAction ? {
      path: `/services/data-verification/data-sets/${dataSetId}/`,
      label,
    } : null,
    external_reader: showParticipantAction ? ownerReaderContext : null,
  };
};

export {
  getVerificationContext,
  getBoardMemberContext,
  getMembershipContext,
  getCompanySignatoryContext,
  getDocumentContext,
  getSubscriptionsContext,
  getConversionContext,
  getAllotmentContext,
  getTokenContext,
  getSimpleTransferContext,
  getTransferContext,
  getBoReportContext,
  getDelegateContext,
  getContactsContext,
  getGovernanceEventContext,
  getInformationContext,
  getPotentialShareholdersContext,
  getCertificateIssuerContext,
  getCertificatesContext,
  getConsortiumMembershipContext,
  getCBDCContext,
  getValuationContext,
  getInvoiceContext,
  getContractUpgradeContext,
  getUtilityTokenContext,
  getPermissionsRegistryContext,
  getMarketsContext,
  getDataRoomContext,
  getDataSetContext,
};
