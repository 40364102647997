/**
 * List of Issuer Registries.
 */

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Formik, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { getApiUrl, getUrl } from 'utils/urls';
import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import FormErrors from '../../components/common/formikErrors';
import { SubmitButton, ReactSelectField } from '../../core/forms/fields';


const CertificateAddIssuerRegistry = ({ companyId, certificateTypeId }) => {
  const [issuerRegistries, setIssuerRegistries] = useState([]);

  useEffect(() => {
    const url = getApiUrl('/certificates/issuer-registries/public/');
    axios.get(url).then(({ data }) => { setIssuerRegistries(data); }).catch(console.error);
  }, [companyId]);


  const onSubmit = (values, actions) => {
    const apiUrl = getApiUrl(`/certificates/${certificateTypeId}/issuer-registries/`);
    axios.post(apiUrl, { address: values.address })
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, getUrl(`/certificates/types/${certificateTypeId}/`));
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Formik
      initialValues={{
        nonFieldErrors: '',
        address: '',
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        address: Yup.string()
          .max(42, gettext('Line is too long'))
          .required(gettext('Required')),
      })}
    >
      {(form, isSubmitting) => (
        <Form>
          <ErrorMessage component={FormErrors} name="nonFieldErrors" />
          <ReactSelectField
            name="address"
            label={gettext('Select issuer registry:')}
            options={issuerRegistries}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.address}
          />
          <SubmitButton disabled={isSubmitting}>{gettext('Add')}</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};


CertificateAddIssuerRegistry.defaultProps = {
  companyId: null,
};

CertificateAddIssuerRegistry.propTypes = {
  companyId: PropTypes.string,
  certificateTypeId: PropTypes.string.isRequired,
};

export default CertificateAddIssuerRegistry;
