/**
 * Adding of issuer to issuer's registry.
 */

import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';

import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { ContactSelectField } from 'contacts/contactSelect/selectField';
import { contactSelectValidation } from 'contacts/contactSelect/validation';
import axios from 'core/axios';
import { getApiUrl, getUrl } from 'utils/urls';
import { parseErrorResponse } from 'core/forms/utils';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import FormErrors from '../../components/common/formikErrors';
import { TextField, SubmitButton } from '../../core/forms/fields';
import { dateToday } from '../../utils/date';


const RegistryAddIssuer = ({ issuerRegistryId }) => {
  const onSubmit = (values, actions) => {
    let expiryTimestamp = '';
    if (values.expiryTimestamp) {
      const [year, month, day] = values.expiryTimestamp.split('-');
      const expiryDate = new Date(year, month - 1, day, 23, 23, 59);
      expiryTimestamp = expiryDate.getTime() / 1000;
    }

    const data = new FormData();
    data.append('legal_entity_id', values.legalEntity.id);
    data.append(snakeCase('issuerId'), values.issuerId);
    data.append(snakeCase('expiryTimestamp'), expiryTimestamp);

    axios.post(getApiUrl(`/certificates/issuer-registries/${issuerRegistryId}/issuers/`), data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          const redirectUrl = getUrl(`/certificates/issuer-registries/${issuerRegistryId}/`);
          handleTransactionCreation(txHash, redirectUrl);
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Formik
      initialValues={{
        nonFieldErrors: '',
        legalEntity: {},
        issuerId: '',
        expiryTimestamp: '',
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        legalEntity: contactSelectValidation(gettext('Required')),
        issuerId: Yup.string()
          .max(128, gettext('Line is too long'))
          .required(gettext('Required')),
        expiryTimestamp: Yup.date()
          .min(dateToday(), gettext("Date can't be in the past")),
      })}
    >
      {({ isSubmitting }) => (
        <Form>
          <ErrorMessage component={FormErrors} name="nonFieldErrors" />
          <ContactSelectField
            name="legalEntity"
            includeCompanyWallets
            includeOwn
            excludeCustodial
            selectContactLabel={gettext('Assign an issuer')}
            label={gettext('Issuer')}
            emptyValueLabel={gettext('No issuer yet')}
          />
          <TextField name="issuerId" label={gettext('Issuer licence ID')} />
          <ErrorMessage component={FormErrors} name="expiryTimeStamp" />
          <label id="expiryTimestamp" htmlFor="expiryTimestamp">{gettext('Expiry date')}</label>
          <Field name="expiryTimestamp" type="date" placeholder="yyyy-mm-dd" />
          <SubmitButton disabled={isSubmitting}>{gettext('Add')}</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

RegistryAddIssuer.propTypes = {
  issuerRegistryId: PropTypes.string.isRequired,
};

export default RegistryAddIssuer;
