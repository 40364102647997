/* Page content component */
import { useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import PageContent from './content';

const FullPageContent = ({
  headerLeft,
  pageHeading,
  actions,
  children,
  pageMessages,
  sidebar,
  actionsMode,
  noPadding,
}) => {
  const contentElement = document.getElementById('full-page-component');
  const root = useMemo(() => createRoot(contentElement), [contentElement]);

  root.render(
    <PageContent
      headerLeft={headerLeft}
      pageHeading={pageHeading}
      actions={actions}
      pageMessages={pageMessages}
      fullscreen
      sidebar={sidebar}
      actionsMode={actionsMode}
      noPadding={noPadding}
    >
      {children}
    </PageContent>,
  );
};

FullPageContent.defaultProps = {
  headerLeft: null,
  actions: null,
  pageMessages: null,
  styles: {},
  sidebar: null,
  actionsMode: 'actionsBar',
  noPadding: false,
};

FullPageContent.propTypes = {
  headerLeft: PropTypes.element,
  pageHeading: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape()),
  pageMessages: PropTypes.node,
  styles: PropTypes.shape(),
  sidebar: PropTypes.element,
  actionsMode: PropTypes.oneOf(['actionsBar', 'priorityActions']),
  noPadding: PropTypes.bool,
};

export default FullPageContent;
