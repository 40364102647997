import { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';


const SubscriptionCertificatesList = () => {
  const [subscriptionCertificates, setSubscriptionCertificates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    axios.get(getApiUrl('/investments/subscription-certificates/'))
      .then(({ data }) => { setSubscriptionCertificates(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, []);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Subscription certificates')}
      headerLeft={<BackButton href={window.location.pathname.split('subscriptions/')[0]} />}
    >
      {Object.entries(subscriptionCertificates).length > 0 ? (
        <ol className="padded-top">
          {subscriptionCertificates.map((subscriptionCert) => (
            <li key={subscriptionCert.id}>
              <Link to={`${pathname}${subscriptionCert.id}/`} key={subscriptionCert.id} className="row-flex card-base">
                {subscriptionCert.name}
              </Link>
            </li>
          ))}
        </ol>
      ) : (
        <ContentLoadingMessage
          isLoading={isLoading}
          loadedMessage={gettext('No subscription certificates')}
        />
      )}
    </PageContent>
  );
};


export default SubscriptionCertificatesList;
