
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'core/axios';
import List from '../../core/lists/list';


const OwnedCertificateContractDetail = ({ certificateTypeId, companyId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [issuerRegistriesList, setIssuerRegistriesList] = useState([]);
  const [certificateDetail, setCertificateDetail] = useState({});

  useEffect(() => {
    const url = `/api/v1/company/${companyId}/certificates/owned/${certificateTypeId}/`;
    axios.get(url).then(({ data }) => { setCertificateDetail(data); }).catch(console.error);
  }, [certificateTypeId, companyId]);

  useEffect(() => {
    const url = `/api/v1/company/${companyId}/certificates/${certificateTypeId}/issuer-registries/`;
    axios.get(url)
      .then(({ data }) => { setIssuerRegistriesList(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [certificateTypeId, companyId]);

  return (
    <>
      <span className="row-flex padded-y">
        <span className="title no-overflow">
          {gettext('Description')}
        </span>
        <span className="font-bold">
          {certificateDetail.description}
        </span>
      </span>
      <section>
        <header className="row-flex not-padded top-margin underline">
          <h3 className="title font-size-16px">{gettext('Issuer registries')}</h3>
        </header>
        <List
          collection={issuerRegistriesList}
          isLoading={isLoading}
          loadingText={gettext('Loading issuer registries...')}
          emptyText={gettext('No issuer registries')}
          rowLink={(item) => `/company/${companyId}/certificates/issuer-registries/${item.id}/`}
        />
      </section>
    </>
  );
};

OwnedCertificateContractDetail.defaultProps = {
  companyId: null,
};

OwnedCertificateContractDetail.propTypes = {
  companyId: PropTypes.string,
  certificateTypeId: PropTypes.string.isRequired,
};

export default OwnedCertificateContractDetail;
