import { useRef, useState } from 'react';

import CheckCertificateForm from './form';
import CertificateResult from './result';

const CheckCertificateView = () => {
  const resultRef = useRef(null);
  const [certificatesFetched, setCertificatesFetched] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const { searchParams } = new URL(window.location.href);

  let certificatesList = <div />;
  if (certificatesFetched && certificates) {
    certificatesList = certificates.map(
      (certificate) => <CertificateResult key={certificate.testKitId} certificate={certificate} />,
    );
  }
  if (certificatesFetched && !certificates.length) {
    certificatesList = (
      <div className="big-top-margin certificate-result centered align-flex-items-center">
        {gettext('No certificates were found')}
      </div>
    );
  }
  return (
    <>
      <h2 className="title big-top-margin align-text-center">{gettext('Check Certificate')}</h2>
      <div className="top-margin limited-width">
        <ol className="numbered">
          {!searchParams.get('id') && <li>{gettext('Scan QR code')}</li>}
          <li>{gettext('Compare passport number')}</li>
          <li>{gettext('Check result and validity')}</li>
        </ol>
        <br />
        <CheckCertificateForm
          setCertificates={setCertificates}
          setCertificatesFetched={setCertificatesFetched}
          resultRef={resultRef}
        />
        <div ref={resultRef}>
          {certificatesList}
        </div>
      </div>
    </>
  );
};

export default CheckCertificateView;
