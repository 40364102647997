import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import { Button, TextField } from 'core/forms/fields';
import { Icon } from 'components/common/icon';
import DownloadContent from 'components/generic/download';
import DescriptionList from './DescriptionList';
import { getQRValue } from '../../utils/personalCode';


const CreateIdentityComponent = ({ onSubmit, passportId, pepper }) => {
  const [qrCodeUrl, setQrCodeUrl] = useState('');

  useEffect(() => {
    const canvas = document.getElementById('qr-code-canvas');
    if (canvas) {
      setQrCodeUrl(canvas.toDataURL('image/png'));
    }
  }, [passportId]);

  return (
    <>
      <TextField label="Passport ID" name="passportId" type="text" />
      <Button onClick={onSubmit}>Create</Button>
      {passportId && pepper && (
      <>
        <hr />
        <h2 className="show-for-print absolute-print title-text">{gettext('Personal Identity Code')}</h2>
        <div className="align-text-right">
          <button type="button" onClick={(_e) => window.print()} aria-label={gettext('Print')}>
            <Icon className="padded-x" name="print" />
          </button>

          <DownloadContent downloadName="personal-qr-code.png" url={qrCodeUrl} />
        </div>
        <div className="absolute-print">
          <QRCode
            id="qr-code-canvas"
            className="qr-code-img"
            value={getQRValue(passportId, pepper)}
            level="H"
            includeMargin
          />
          <DescriptionList
            className="dl-with-accent"
            data={[['Passport ID', passportId], ['Personal Security Code', pepper]]}
          />
        </div>
      </>
      )}
    </>
  );
};

CreateIdentityComponent.defaultProps = {
  onSubmit: () => { },
};

CreateIdentityComponent.propTypes = {
  onSubmit: PropTypes.func,
  passportId: PropTypes.string.isRequired,
  pepper: PropTypes.string.isRequired,

};
export default CreateIdentityComponent;
