/**
 * List of Issued Certificates.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import axios from 'core/axios';
import List from 'core/lists/list';
import { formatDate } from 'utils/date';
import RowWithOptions from 'core/lists/rows/rowWithOptions';


const IssuedCertificatesList = ({ companyId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [issuerRegistriesList, setIssuerRegistriesList] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    axios.get(`/api/v1/${companyId ? `company/${companyId}/` : ''}certificates/issued/`)
      .then(({ data }) => { setIssuerRegistriesList(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [companyId]);

  // @todo convert to component
  const status = (item) => {
    if (item.revoked) {
      return <div className="alert">{gettext('Revoked')}</div>;
    }
    if (item.expiry_timestamp && (item.expiry_timestamp * 1000) < Date.now()) {
      return <div className="alert">{gettext('Expired')}</div>;
    }
    return <div className="success-color">{gettext('Valid')}</div>;
  };

  const optionsList = (_item) => [];

  // const optionsList = item => [
  //   <Link
  //     href={`/${companyId ? `company/${companyId}/` : ''}certificates/issue/${item.person_hash}/${item.index}/revoke/`}
  //     className="option alert-red text-tiny"
  //   >
  //     <Icon name="times" />
  //     {gettext('Revoke')}
  //   </Link>,
  // ];

  return (
    <List
      collection={issuerRegistriesList}
      rowTitle={(item) => item.certificate_description}
      isLoading={isLoading}
      RowComponent={RowWithOptions}
      date={(item) => (
        <>
          <span className="date">{formatDate(new Date(item.sample_timestamp * 1000))}</span>
          <br />
        </>
      )}
      rowKey={(item) => item.test_kit_id}
      additionalData={(item) => (
        <>
          <br />
          {item.test_kit_id}
          <br />
          {status(item)}
          {!!item.expiry_timestamp && (
            <div className="font-italic text-tiny">
              {`${gettext('Expiry date:')} ${formatDate(new Date(item.expiry_timestamp * 1000))}`}
            </div>
          )}
        </>
      )}
      options={(item) => (optionsList(item))}
      loadingText={gettext('Loading certificates...')}
      emptyText={gettext('No certificates')}
    />
  );
};


IssuedCertificatesList.defaultProps = {
  companyId: null,
};

IssuedCertificatesList.propTypes = {
  companyId: PropTypes.string,
};

export default IssuedCertificatesList;
