import PropTypes from 'prop-types';
import Loader from '../../../components/common/loader';
import { RadioButtonGroup, Button } from '../../../core/forms/fields';
import CreateIdentityComponent from './CreateIdentityComponent';
import ScanIdentityComponent from './ScanIdentityComponent';


const identityTypes = [{ value: 'create', label: 'Create new identity' }, { value: 'scan', label: 'Scan existing ID' }];


const ChooseIdentity = ({
  isSubmitting,
  identityMethod,
  passportId,
  pepper,
  handleCreateIdentity,
  handleQrScan,
  handleFormsSwitch,
}) => (
  <Loader isLoading={isSubmitting}>
    <RadioButtonGroup
      name="identityMethod"
      options={identityTypes}
    />

    {identityMethod === 'create' && (
    <CreateIdentityComponent
      onSubmit={handleCreateIdentity}
      passportId={passportId}
      pepper={pepper}
    />
    )}

    {identityMethod === 'scan' && (
    <ScanIdentityComponent
      onScan={handleQrScan}
      passportId={passportId}
      pepper={pepper}
    />
    )}
    {passportId && pepper && (
    <Button onClick={handleFormsSwitch}>{gettext('Next')}</Button>
    )}
  </Loader>
);

ChooseIdentity.defaultProps = {
  isSubmitting: false,
  identityMethod: 'create',
  passportId: '',
};

ChooseIdentity.propTypes = {
  isSubmitting: PropTypes.bool,
  identityMethod: PropTypes.string,
  passportId: PropTypes.string,
  handleCreateIdentity: PropTypes.func.isRequired,
  handleFormsSwitch: PropTypes.func.isRequired,
  handleQrScan: PropTypes.func.isRequired,
  pepper: PropTypes.string.isRequired,
};

export default ChooseIdentity;
