import { icons } from 'core/icons';
import { AllowedCompanyActions, isCompanyActionAllowed } from 'core/allowedActions';
import { MenuItemsIds } from '../constants';
import { MenuItemProps } from '../types';
import {
  account,
  artworksService,
  assets,
  associationsMembership,
  dashboard,
  documents,
  events,
  inbox,
  markets,
} from './shared';

export const companyMenuItems: MenuItemProps[] = [
  dashboard,
  inbox,
  events,
  assets,
  {
    id: MenuItemsIds.LIABILITIES,
    label: gettext('Liabilities'),
    url: '/liabilities/',
    icon: icons.liabilities,
    activeIcon: icons.liabilitiesActive,
    subitems: [
      {
        id: MenuItemsIds.LIABILITIES_EQUITY,
        label: gettext('Equity'),
        url: '/liabilities/equity/',
        isHidden: !isCompanyActionAllowed(AllowedCompanyActions.LIABILITIES_ISSUE),
      },
      {
        id: MenuItemsIds.LIABILITIES_PAYMENT_TOKENS,
        label: gettext('Cash'),
        url: '/payment-tokens/',
        isHidden: !isCompanyActionAllowed(AllowedCompanyActions.PAYMENT_TOKEN),
      },
      {
        id: MenuItemsIds.LIABILITIES_UTILITY_TOKENS,
        label: gettext('Utility'),
        url: '/liabilities/utility/',
      },
    ],
  },
  {
    id: MenuItemsIds.GOVERNANCE,
    label: gettext('Governance'),
    url: '/governance/',
    icon: icons.signatories,
    activeIcon: icons.signatoriesActive,
    subitems: [
      {
        id: MenuItemsIds.GOVERNANCE_OWNERSHIP,
        label: gettext('Ownership'),
        url: '/ownership/',
        icon: icons.liabilities,
        activeIcon: icons.liabilitiesActive,
      },
      {
        id: MenuItemsIds.GOVERNANCE_BOARDROOM,
        label: gettext('Boardroom'),
        url: '/boardroom/',
        isHidden: !isCompanyActionAllowed(AllowedCompanyActions.EXECUTIVE_BOARD),
      },
      {
        id: MenuItemsIds.GOVERNANCE_SIGNATORIES,
        label: gettext('Signatories'),
        url: '/signatories/',
        isHidden: !isCompanyActionAllowed(AllowedCompanyActions.SIGNATORIES),
        icon: icons.signatories,
        activeIcon: icons.signatoriesActive,
        isDefault: true,
      },
      {
        id: MenuItemsIds.GOVERNANCE_MEMBERS,
        label: gettext('Members'),
        url: '/members/',
        isDefault: true,
        isHidden: !isCompanyActionAllowed(AllowedCompanyActions.MEMBERSHIP),
      },
    ],
  },
  {
    id: MenuItemsIds.DATA_ROOMS,
    label: gettext('Data Rooms'),
    url: '/data-rooms/',
    icon: icons.dataRooms,
    activeIcon: icons.dataRoomsActive,
  },
  {
    id: MenuItemsIds.SERVICES,
    label: gettext('Services'),
    url: '/services/',
    icon: icons.transfer,
    activeIcon: icons.transferActive,
    subitems: [
      {
        id: MenuItemsIds.SERVICES_VERIFY,
        label: gettext('Verification'),
        url: '/verify/',
      },
      {
        id: MenuItemsIds.SERVICES_CERTIFICATES_ISSUER,
        label: gettext('Certificates'),
        url: '/certificates/',
      },
      {
        id: MenuItemsIds.SERVICES_CUSTODIAN,
        label: gettext('Custodian'),
        url: '/custodian/',
      },
      artworksService,
      {
        id: MenuItemsIds.SERVICES_REGISTRIES,
        label: gettext('Registries'),
        url: '/registries/',
      },
      {
        id: MenuItemsIds.SERVICES_DATA_ROOMS_VERIFIER,
        label: gettext('Data Rooms'),
        url: '/services/data-verification/',
        icon: icons.dataRooms,
        activeIcon: icons.dataRoomsActive,
      },
    ],
  },
  {
    id: MenuItemsIds.MEMBERSHIPS,
    label: gettext('Memberships'),
    url: '/memberships/',
    icon: icons.boardroom,
    activeIcon: icons.boardroomActive,
    subitems: [
      associationsMembership,
      {
        id: MenuItemsIds.MEMBERSHIPS_BLOCKCHAIN,
        label: gettext('Blockchain'),
        url: '/blockchain/',
        isDefault: true,
      },
    ],
  },
  documents,
  markets,
  account,
];
