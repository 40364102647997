/**
 * Detail of issuer in issuer registry.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'core/axios';


const IssuerDetail = ({ companyId, issuerRegistryId, issuerId }) => {
  const [issuer, setIssuer] = useState({});

  useEffect(() => {
    const url = `/api/v1/company/${companyId}/certificates/issuer-registries/${issuerRegistryId}/issuers/${issuerId}/`;
    axios.get(url).then(({ data }) => { setIssuer(data); }).catch(console.error);
  }, [companyId, issuerRegistryId, issuerId]);

  return (
    <span className="row-flex padded-y">
      <span className="title no-overflow">
        {gettext('Name')}
      </span>
      <span className="font-bold">{issuer.name}</span>
    </span>
  );
};

IssuerDetail.defaultProps = {
  companyId: null,
};

IssuerDetail.propTypes = {
  companyId: PropTypes.string,
  issuerRegistryId: PropTypes.string.isRequired,
  issuerId: PropTypes.string.isRequired,
};

export default IssuerDetail;
