import PropTypes from 'prop-types';

import DescriptionList from '../issuedCertificates/parts/DescriptionList';
import { formatDate } from '../../utils/date';
import CheckMark from './checkMark';

const CertificateResult = ({ certificate }) => {
  if (Object.keys(certificate).length === 0 && certificate.constructor === Object) {
    return null;
  }
  const data = [
    ['Passport Number', certificate.passportId],
    ['Issuer Name and ID', `${certificate.issuerName}, ${certificate.issuerIds.join(', ')}`],
    ['Valid Until', formatDate(certificate.expiryDate)],
    ['Test Date', formatDate(certificate.sampleDate)],
  ];
  certificate.extraData && data.push(['Public Data', certificate.extraData]);
  certificate.encryptedData && data.push(['Private Data', certificate.encryptedData]);
  return (
    <div className="big-top-margin certificate-result">
      <div className="align-text-center padded-top secondary-color">
        {gettext(`Responsible Certificate Owner: ${certificate.ownerName}`)}
      </div>
      <h3 className="align-text-center padded-top">{certificate.description}</h3>
      <div className="align-text-center">
        {
          certificate.revoked
            ? gettext('This certificate has expired.')
            : certificate.expired && gettext('This certificate has been revoked')
        }
      </div>
      {!certificate.revoked && !certificate.expired && <CheckMark size="medium" className="big-top-margin" />}
      <br />
      <DescriptionList
        className="print-identity dl-with-accent"
        data={data}
      />
    </div>
  );
};

CertificateResult.defaultProps = {
  certificate: {},
};

CertificateResult.propTypes = {
  certificate: PropTypes.shape({
    description: PropTypes.string,
    expiryDate: PropTypes.instanceOf(Date),
    sampleDate: PropTypes.instanceOf(Date),
    issuerName: PropTypes.string,
    issuerIds: PropTypes.arrayOf(PropTypes.string),
    ownerName: PropTypes.string,
    passportId: PropTypes.string,
    expired: PropTypes.bool,
    revoked: PropTypes.bool,
    extraData: PropTypes.string,
    encryptedData: PropTypes.string,
  }),
};


export default CertificateResult;
