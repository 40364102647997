import PropTypes from 'prop-types';
import { Icon } from '../common/icon';


const DownloadContent = ({ downloadName, url }) => {
  const handleDownload = (_e) => {
    const link = document.createElement('a');
    link.download = downloadName;
    link.href = url;

    link.click();
  };

  return (
    <button type="button" onClick={() => handleDownload()} aria-label={gettext('Download')}>
      <Icon className="left-padding" name="file-download" />
    </button>
  );
};

DownloadContent.defaultProps = {
  downloadName: 'download.png',
};


DownloadContent.propTypes = {
  downloadName: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default DownloadContent;
