import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ModalWithContent as Modal } from '@trustwise/design-system';
import axios from 'core/axios';
import { getApiUrl, getUrl } from 'utils/urls';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import LegalAgreementsPart from 'subscriptions/generic/parts/legalAgreements';
import { PageContent } from 'core/page';
import { toMonetaryValue, txHashResponseHandler } from 'core/utils';
import BackButton from 'core/page/parts/backButton';
import { BasicRow, BasicRowWithModal } from 'core/lists/rows/basicRow';
import DocumentsRoutes from 'media/routes';


const SubscriptionCertificatesDetail = () => {
  const [showReferenceNumber, setShowReferenceNumber] = useState(false);
  const [subscriptionCertificate, setSubscriptionCertificate] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { subscriptionCertificateId } = useParams();
  const baseUrl = getApiUrl(`/assets/equity/subscription-certificates/${subscriptionCertificateId}/`);
  const timestampNow = new Date() / 1000;

  useEffect(() => {
    setIsLoading(true);
    axios.get(baseUrl)
      .then(({ data }) => { setSubscriptionCertificate(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [baseUrl]);

  const actions = [];
  /** @todo re enabled when the requirements for the update are clarified
  if (
    timestampNow < subscriptionCertificate.closing_timestamp
    && ['subscriptions_allotted', 'created'].includes(subscriptionCertificate.status)
  ) {
    actions.push({
      type: 'form',
      icon: 'edit',
      // eslint-disable-next-line max-len
      label: subscriptionCertificate.power_of_attorney ? gettext('Revoke power of attorney') : gettext('Grant power of attorney'),
      onClick: () => {
        axios.patch(`${baseUrl}update-power-of-attorney/`)
          .then(({ data }) => { txHashResponseHandler(data, pathname); })
          .catch(console.error);
      },
    });
  }
  */
  if (
    !subscriptionCertificate.payed
    && timestampNow < subscriptionCertificate.closing_timestamp
    && subscriptionCertificate.status === 'subscriptions_allotted'
  ) {
    actions.push({
      type: 'form',
      icon: 'check',
      label: gettext('Confirm payment'),
      onClick: () => {
        axios.patch(`${baseUrl}confirm-payment/`)
          .then(({ data }) => { txHashResponseHandler(data, window.location.pathname); })
          .catch(console.error);
      },
    });
  }
  return (
    <PageContent
      fullscreen
      pageHeading={subscriptionCertificate.name || ''}
      headerLeft={<BackButton href={getUrl('/equity')} />}
      actions={actions}
    >
      {Object.entries(subscriptionCertificate).length > 0 ? (
        <>
          <BasicRow title={gettext('Subscribed number of shares')} value={subscriptionCertificate.no_of_shares} />
          <BasicRow
            title={gettext('Issuing price per share')}
            value={toMonetaryValue(subscriptionCertificate.currency, subscriptionCertificate.price_per_share)}
          />
          {subscriptionCertificate.status !== 'created' && (
            <>
              <BasicRow
                title={gettext('Payment due')}
                value={toMonetaryValue(
                  subscriptionCertificate.currency,
                  (subscriptionCertificate.no_of_shares * subscriptionCertificate.price_per_share),
                )}
              />
              <BasicRowWithModal
                title={gettext('Reference number')}
                value={subscriptionCertificate.reference_number}
                onClick={() => { setShowReferenceNumber(true); }}
              />
              <Modal
                id={subscriptionCertificate.reference_number}
                isOpen={showReferenceNumber}
                onClose={() => { setShowReferenceNumber(false); }}
              >
                <div>
                  {gettext(
                    `Reference number will help the company to find and identify your payment when composing
                    the final list of subscribers.
                    It is important to include it into the payment description.`,
                  )}
                </div>
              </Modal>
            </>
          )}
          <BasicRow title={gettext('Share class name')} value={subscriptionCertificate.share_class.name} />
          <BasicRow
            title={gettext('Nominal value')}
            value={toMonetaryValue(
              subscriptionCertificate.share_class.currency,
              subscriptionCertificate.share_class.nominal_value,
            )}
          />
          <BasicRow
            title={gettext('Power of attorney granted')}
            value={subscriptionCertificate.power_of_attorney ? gettext('Yes') : gettext('No')}
          />
          <LegalAgreementsPart
            powerOfAttorneyText={subscriptionCertificate.power_of_attorney_text}
            legalText={subscriptionCertificate.legal_text}
          />
          <DocumentsRoutes
            relatedObjectModel="contract"
            relatedObjectId={subscriptionCertificate.subscription_id}
            queryParams={{ subscription_id: subscriptionCertificate.subscription_id }}
            isNested
          />
        </>
      ) : (
        <ContentLoadingMessage
          isLoading={isLoading}
          loadedMessage={gettext('Subscription not found.')}
        />
      )}
    </PageContent>
  );
};

export default SubscriptionCertificatesDetail;
