
import web3 from 'web3';

import { OLD_SEPARATOR, SEPARATOR } from '../constants';

const readerAppURL = 'https://certificates.swisstrustnet.org/';

export const createPersonHash = (passportId, pepper) => web3.utils.sha3(`0000${SEPARATOR}${passportId}${SEPARATOR}${pepper}`);

export const createQrURL = (passportId, pepper) => {
  const urlParams = new URLSearchParams();
  urlParams.set('id', passportId);
  urlParams.set('pp', pepper);
  return `${readerAppURL}?${urlParams.toString()}`;
};

// @todo DRY with format definition
export const getQRValue = (passportId, pepper) => `0000${SEPARATOR}${passportId}${SEPARATOR}${pepper}`;

// @todo add support for more procedure codes (#1006)
export const parsePersonalCode = (personalCode) => {
  if (personalCode.includes(readerAppURL)) {
    const { searchParams } = new URL(personalCode);
    return [searchParams.get('id'), searchParams.get('pp')];
  }
  if (personalCode.includes(OLD_SEPARATOR)) {
    return personalCode.split(OLD_SEPARATOR);
  }
  if (personalCode.includes(SEPARATOR)) {
    const personalCodeArray = personalCode.split(SEPARATOR);
    const procedureCode = personalCodeArray.shift();
    if (procedureCode === '0000') {
      return personalCodeArray;
    }
    console.error(`Unsupported procedure code: ${procedureCode}`);
  }
  return ['', ''];
};
