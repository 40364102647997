import { Features } from '../features';

export const MenuItemsIds = Features;

export const defaultPersonalMenuFavorites: string[] = [
  MenuItemsIds.DASHBOARD, MenuItemsIds.INBOX,
  MenuItemsIds.ASSETS_TRANSFERS, MenuItemsIds.MARKETS,
];
export const defaultCompanyMenuFavorites: string[] = [
  MenuItemsIds.DASHBOARD, MenuItemsIds.GOVERNANCE_SIGNATORIES,
  MenuItemsIds.GOVERNANCE_OWNERSHIP, MenuItemsIds.MARKETS,
];
