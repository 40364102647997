import PropTypes from 'prop-types';


const DescriptionList = ({ data, className }) => {
  if (!data || !data.length) {
    return null;
  }
  return (
    <dl className={className}>
      {data.map(
        ([label, value]) => (
          <div key={`${label}-${value}`}>
            <dd>{label}</dd>
            <dt>{value}</dt>
          </div>
        ),
      )}
    </dl>
  );
};

DescriptionList.defaultProps = {
  data: [],
  className: '',
};

DescriptionList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.string),
  ),
  className: PropTypes.string,
};

export default DescriptionList;
