export const Features = {
  ACCOUNT: 'account',
  ASSETS: 'assets',
  ASSETS_ARTWORKS: 'assets-artworks',
  ASSETS_EQUITY: 'assets-equity',
  ASSETS_PAYMENT_TOKENS: 'assets-payment-tokens',
  ASSETS_TRANSFERS: 'assets-transfers',
  ASSETS_UTILITY_TOKENS: 'assets-utility-tokens',
  CONTACTS: 'contacts',
  DASHBOARD: 'dashboard',
  DATA_ROOMS: 'data-rooms',
  DATA_ROOM_CREATOR: 'data-rooms-creator',
  DOCUMENTS: 'documents',
  DOCUMENTS_ARCHIVE: 'documents-archive',
  DOCUMENTS_SIGNING: 'documents-signing',
  EVENTS: 'events',
  GOVERNANCE: 'governance',
  GOVERNANCE_BOARDROOM: 'governance-boardroom',
  GOVERNANCE_MEMBERS: 'governance-members',
  GOVERNANCE_OWNERSHIP: 'governance-ownership',
  GOVERNANCE_SIGNATORIES: 'governance-signatories',
  INBOX: 'inbox',
  LIABILITIES: 'liabilities',
  LIABILITIES_EQUITY: 'liabilities-equity',
  LIABILITIES_PAYMENT_TOKENS: 'liabilities-payment-tokens',
  LIABILITIES_UTILITY_TOKENS: 'liabilities-utility-tokens',
  MARKETS: 'markets',
  MEMBERSHIPS: 'memberships',
  MEMBERSHIPS_ASSOCIATIONS: 'memberships-associations',
  MEMBERSHIPS_BLOCKCHAIN: 'memberships-blockchain',
  MEMBERSHIPS_BOARDROOM: 'memberships-boardroom',
  SERVICES: 'services',
  SERVICES_ARBITER: 'services-arbiter',
  SERVICES_CERTIFICATES_ISSUER: 'services-certificates-issuer',
  SERVICES_ARTWORKS: 'services-artworks',
  SERVICES_CUSTODIAN: 'services-custodian',
  SERVICES_REGISTRIES: 'services-registries',
  SERVICES_VERIFY: 'services-verify',
  SERVICES_DATA_ROOMS_VERIFIER: 'services-data-rooms-verifier',
} as const;

export type Feature = typeof Features[keyof typeof Features];
