/**
 * Form to initialize a transfer
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { snakeCase } from 'lodash';

import { parseErrorResponse } from 'core/forms/utils';
import {
  TextField,
  TextAreaField,
  SubmitButton,
  MonetaryField,
  DateTimeField,
} from 'core/forms/fields';
import { ContactSelectField } from 'contacts/contactSelect/selectField';
import axios from 'core/axios';
import SwitchableDocumentUpload from 'media/documents/switchableUpload';
import { dateToday, isToday, isTodayTimeInFuture } from 'utils/date';
import { DocumentModel } from 'media/models';
import { convertBooleanString } from 'core/utils';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import { ProvidedServices } from 'core/providedServices';
import { IncludeOnlyOptions } from 'contacts/contactSelect/constants';
import { contactSelectValidation } from 'contacts/contactSelect/validation';
import FormErrors from '../components/common/formikErrors';
import { getApiUrl, getUrl } from '../utils/urls';

const InitializeTransfer = ({ tokenId, portfolioId, transferType, currency, tokenIsSuspended }) => {
  const [spaInitValue, setSpaInitValue] = useState('');
  const tokenIsSuspendedBool = convertBooleanString(tokenIsSuspended);

  useEffect(() => {
    axios.get('/api/v1/assets/transfer/spa/').then(
      ({ data: { data } }) => setSpaInitValue(data),
    );
  }, []);

  const onSubmit = (values, actions) => {
    const data = new FormData();
    const {
      date,
      documentType,
      subject,
      files,
      buyer,
      arbiter,
      ...transferValues
    } = values;
    Object.entries(transferValues).forEach(([key, value]) => {
      data.append(snakeCase(key), value);
    });
    data.append('portfolio', portfolioId);
    data.append('buyer', buyer.portfolio);
    data.append('arbiter', arbiter.id);
    data.append('token', tokenId);
    data.append('is_restricted', transferType === 'restricted_transfer');
    if (files.length) {
      data.append('document.document_type', documentType);
      files.forEach((file) => {
        data.append('document.files', file);
      });
      data.append('document.date', date);
      data.append('document.subject', subject);
    }
    axios.post(getApiUrl('/transfers/'), data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, getUrl('/equity/'));
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Formik
      initialValues={{
        nonFieldErrors: '',
        buyer: {},
        arbiter: {},
        amount: 0,
        currency,
        pricePerShare: (0).toFixed(2),
        total: (0).toFixed(2),
        settlementDate: dateToday(),
        settlementTime: '18:00',
        spaAppendix: spaInitValue,
        ...DocumentModel.uploadInitialValues,
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        buyer: contactSelectValidation(),
        arbiter: contactSelectValidation(),
        amount: Yup.number().min(1, gettext('Value should be above 0')).required(gettext('This field is required')),
        currency: Yup.string().max(3, gettext('Line is too long')).required(gettext('This field is required')),
        pricePerShare: Yup.number().positive(gettext('Value should be above 0')).required(gettext('This field is required')),
        total: Yup.number(),
        settlementDate: Yup.date().min(
          dateToday(),
          gettext("Settlement date can't be in the past"),
        ).required('This field is required'),
        settlementTime: Yup.string().required('This field is required').when('settlementDate', {
          is: (settlementDate) => !!settlementDate && isToday(settlementDate),
          then: Yup.string().test('time', gettext('Time should be in the future'), isTodayTimeInFuture),
        }),
        spaAppendix: Yup.string(),
      }).concat(DocumentModel.simpleUploadValidation)}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <ErrorMessage component={FormErrors} name="nonFieldErrors" />
          <ContactSelectField
            name="buyer"
            includeCompanyWallets
            withPortfolios
            selectContactLabel={gettext('Assign a recipient')}
            addNewLabel={gettext('Add new recipient')}
            emptyValueLabel={gettext('No recipient yet')}
            label={gettext('Recipient')}
          />
          <ContactSelectField
            name="arbiter"
            includeCompanyWallets
            includeOnly={IncludeOnlyOptions.PUBLIC}
            serviceType={ProvidedServices.ARBITER}
            selectContactLabel={gettext('Assign an arbiter')}
            emptyValueLabel={gettext('No arbiter yet')}
            label={gettext('Arbiter')}
          />
          <TextField type="number" name="amount" label={gettext('Amount')} />
          <MonetaryField
            label={gettext('Price per share')}
            currencyName="currency"
            valueName="pricePerShare"
          />
          <TextField
            value={(values.pricePerShare * values.amount).toFixed(2)}
            type="number"
            name="total"
            label={gettext('Total')}
            disabled
          />
          <DateTimeField label={gettext('Settlement Date and Time')} dateName="settlementDate" timeName="settlementTime" />
          <TextAreaField label={gettext('Share Purchase Agreement:')} name="spaAppendix" />
          <SwitchableDocumentUpload />
          <SubmitButton disabled={isSubmitting || tokenIsSuspendedBool}>{gettext('Transfer')}</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

InitializeTransfer.defaultProps = {
  tokenIsSuspended: undefined,
};

InitializeTransfer.propTypes = {
  tokenId: PropTypes.string.isRequired,
  portfolioId: PropTypes.string.isRequired,
  transferType: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  tokenIsSuspended: PropTypes.string,
};

export default InitializeTransfer;
