/* A simple row. */

import PropTypes from 'prop-types';
import Row from './row';
import RowOptionToggle from './rowOptionToggle';

const RowWithOptions = ({ item, rowLink, title, date, options, right, additionalData }) => {
  const optionsItems = options(item);

  return (
    <span className="row-flex not-padded">
      {!!optionsItems.length && (
        <span className={`row-options-list options-${optionsItems.length} show-for-small-only`}>
          {optionsItems}
        </span>
      )}
      <Row
        classNames={`movable-row items-${optionsItems.length}`}
        item={item}
        title={title}
        rowLink={rowLink}
        date={date(item)}
        additionalData={additionalData(item)}
        right={optionsItems.length ? (
          <span className="font-bold no-wrap">
            {right(item)}
            <span>
              <RowOptionToggle />
            </span>
          </span>
        ) : undefined}
      />
    </span>
  );
};

RowWithOptions.defaultProps = {
  additionalData: () => { },
  title: () => {},
  rowLink: () => {},
  date: () => { },
  right: () => { },
};

RowWithOptions.propTypes = {
  item: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  title: PropTypes.func,
  rowLink: PropTypes.func,
  additionalData: PropTypes.func,
  date: PropTypes.func,
  right: PropTypes.func,
  options: PropTypes.func.isRequired,
};

export default RowWithOptions;
