import PropTypes from 'prop-types';
import filesize from 'filesize';
import { getNativeFileUrl } from 'media/utils';
import { iconNames, mediaTypes } from 'media/constants/fileTypes';

export default class FileModel {
  constructor({
    id,
    timestamp,
    name,
    mediaType,
    type,
    fetchUrl,
    size,
    keccak256,
    isEditable,
    thumbnail,
    preview = '',
    path = '',
  }) {
    this.id = id;
    this.timestamp = timestamp || null;
    this.name = name;
    this.mediaType = mediaType || type;
    this.fetchUrl = fetchUrl;
    this.size = size;
    this.keccak256 = keccak256;
    this.thumbnail = thumbnail || '';
    this.isEditable = isEditable || false;
    this.path = path;
    this.preview = preview;
  }

  static propType = PropTypes.shape({
    id: PropTypes.number,
    timestamp: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    mediaType: PropTypes.string,
    fetchUrl: PropTypes.string,
    size: PropTypes.number,
    keccak256: PropTypes.string,
    thumbnail: PropTypes.string,
    isEditable: PropTypes.bool,
    path: PropTypes.string,
    preview: PropTypes.string,
  });

  update(data) { Object.entries(data).forEach(([key, value]) => { this[key] = value; }); }

  get isImage() { return this.mediaType.startsWith('image/'); }

  get isPdf() { return this.mediaType === 'application/pdf'; }

  get nativeUrl() {
    return getNativeFileUrl(
      this.fetchUrl,
      this.name,
      this.mediaType,
      this.timestamp,
      this.keccak256,
    );
  }

  get previewSource() { return this.fetchUrl || this.preview; }

  get iconName() {
    const iconName = iconNames.find((option) => option.id === this.mediaType);
    return iconName ? iconName.text : '';
  }

  get mediaTypeDisplayName() {
    const mediaTypeName = mediaTypes.find((option) => option.id === this.mediaType);
    return mediaTypeName ? mediaTypeName.text : '';
  }

  get readableFileSize() { return filesize(this.size); }

  static fromNativeFile(obj) { return obj instanceof this ? obj : new this(obj); }
}
