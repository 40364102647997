/**
 * Issue Certificate.
 */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage, Field, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { camelCase } from 'lodash';
import QRCode from 'qrcode.react';

import axios from 'core/axios';
import { getApiUrl, getUrl } from 'utils/urls';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import { dateToday, formatTime, roundDateTime } from 'utils/date';
import { Button, DateTimeField, ReactSelectField, SubmitButton, TextField } from 'core/forms/fields';
import FormErrors from 'components/common/formikErrors';
import generatePepper from 'utils/generatePepper';
import DescriptionList from './parts/DescriptionList';
import { createPersonHash, getQRValue, parsePersonalCode } from '../utils/personalCode';
import { encrypt, hasCryptoSupport } from '../utils/encryption';
import ChooseIdentity from './parts/chooseIdentity';


const IssueCertificateFormComponent = ({ companyId, passportId, setPassportId, pepper, setPepper }) => {
  const { isSubmitting, submitForm, values, validateField } = useFormikContext();

  // @todo consider useReducer instead multiple useState
  const [certificateFrom, setCertificateFrom] = useState(false);
  const [issuerCertificatesOptions, setIssuerCertificateOptions] = useState([]);
  const [issuerRegistriesOptions, setIssuerRegistriesOptions] = useState([]);
  const [qrCodeValid, setQrCodeValid] = useState(true);

  useEffect(() => {
    axios.get(getApiUrl('/certificates/types/'))
      .then(({ data }) => { setIssuerCertificateOptions(data); })
      .catch(console.error);
  }, [companyId]);

  useEffect(() => {
    setPepper('');
    setPassportId('');
    setQrCodeValid(true);
  }, [values.identityMethod, setPepper, setPassportId]);

  const handleCreateIdentity = (_e) => {
    validateField('passportId');
    setPassportId(values.passportId);
    setPepper(generatePepper(8));
  };

  const handleFormsSwitch = (_e) => {
    setCertificateFrom(!certificateFrom);
  };

  const handleQrScan = (result) => {
    if (!result) {
      // TODO handle error
      return;
    }
    const [qrPassportId, qrPepper] = parsePersonalCode(result);
    if (!qrPassportId || !qrPepper) {
      setQrCodeValid(false);
      // TODO handle error
      return;
    }
    setPassportId(qrPassportId);
    setPepper(qrPepper);
    setQrCodeValid(true);
  };

  return (
    <Form>
      {(!certificateFrom) && (
        <>
          <h2>{gettext('Choose Identity')}</h2>
          <ChooseIdentity
            isSubmitting={isSubmitting}
            passportId={passportId}
            identityMethod={values.identityMethod}
            pepper={pepper}
            handleCreateIdentity={handleCreateIdentity}
            handleQrScan={handleQrScan}
            handleFormsSwitch={handleFormsSwitch}
          />
        </>
      )}
      {(!qrCodeValid) && (
        <div className="alert">
          {gettext('ID is not found. Please, make sure you are scanning the correct QR code or create a new identity')}
        </div>
      )}
      {(certificateFrom) && (
        <>
          { passportId && pepper && (
            <>
              <div className="row-flex">
                <QRCode value={getQRValue(passportId, pepper)} className="qr-code-img small" level="H" />
                <DescriptionList
                  rowPadding="not-padded"
                  className="dl-with-accent right-align"
                  data={[['Passport ID', passportId]]}
                />
              </div>
              <hr />
            </>
          )}
          <h2>{gettext('Issue Certificate')}</h2>
          <ReactSelectField
            label={gettext('Certificate Type:')}
            name="certificateAddress"
            options={issuerCertificatesOptions}
            getOptionLabel={(option) => option.description}
            getOptionValue={(option) => option.address}
            setValue={(option) => setIssuerRegistriesOptions(option.issuer_registries)}
          />
          {!!issuerRegistriesOptions.length && (
            <ReactSelectField
              label={gettext('Can be revoked by the owner of issuer registry:')}
              name="registryAddress"
              options={issuerRegistriesOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.address}
            />
          )}
          <TextField label={gettext('Unique ID')} name="testKitId" type="text" />
          <DateTimeField label={gettext('Date and Time')} dateName="sampleDate" timeName="sampleTime" />
          <ErrorMessage component={FormErrors} name="expiryDate" />
          <Field name="expiryDate" type="hidden" />
          <TextField name="extraData" type="hidden" />
          {hasCryptoSupport && <TextField label={gettext('Private Data')} name="dataToEncrypt" type="text" />}
          <Button className="button-outlined" onClick={handleFormsSwitch}>{gettext('Previous')}</Button>
          <SubmitButton
            onClick={() => { submitForm(); }}
            disabled={isSubmitting}
          >
            {gettext('Submit')}
          </SubmitButton>
        </>
      )}
    </Form>
  );
};

const IssueCertificateForm = ({ companyId, issuerAddress }) => {
  // @todo consider useReducer instead multiple useState
  const [pepper, setPepper] = useState('');
  const [passportId, setPassportId] = useState('');

  const postData = (data, actions) => {
    axios.post(getApiUrl('/certificates/issued/'), data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, getUrl('/certificates/issue/'));
        },
      )
      .catch(({ response: { data: errors } }) => {
        Object.keys(errors).forEach((key) => {
          let fieldName = '';
          switch (key) {
            case 'sample_timestamp':
              fieldName = 'sampleDate';
              break;
            case 'expiry_timestamp':
              fieldName = 'expiryDate';
              break;
            default:
              fieldName = camelCase(key);
              break;
          }
          actions.setFieldError(fieldName, errors[key].join(' '));
        });
      })
      .finally(() => { actions.setSubmitting(false); });
  };

  const onSubmit = (values, actions) => {
    // @todo move personHash creation to utils
    const personHash = createPersonHash(passportId, pepper);
    // @todo introduce moment.js or move date object creation to utils
    const [sampleYear, sampleMonth, sampleDay] = values.sampleDate.split('-');
    const [sampleHour, sampleMinute] = values.sampleTime.split(':');
    const sampleDate = new Date(sampleYear, sampleMonth - 1, sampleDay, sampleHour, sampleMinute, 0, 0);
    let expiryTimestamp = null;
    if (values.expiryDate) {
      const [expiryYear, expiryMonth, expiryDay] = values.expiryDate.split('-');
      const expiryDate = new Date(expiryYear, expiryMonth - 1, expiryDay, 23, 59, 59, 999);
      expiryTimestamp = Math.floor(expiryDate / 1000);
    }
    const data = {
      person_hash: personHash,
      sample_timestamp: Math.floor(sampleDate / 1000),
      expiry_timestamp: expiryTimestamp,
      test_kit_id: values.testKitId,
      certificate_address: values.certificateAddress,
      registry_address: values.registryAddress,
      issuer_address: issuerAddress,
      extra_data: values.extraData,
      encrypted_data: '',
    };
    if (values.dataToEncrypt && hasCryptoSupport) {
      encrypt(values.dataToEncrypt, pepper).then(
        (encryptedData) => postData({ ...data, encrypted_data: encryptedData }, actions),
      );
    } else {
      postData(data, actions);
    }
  };

  return (
    <Formik
      initialValues={{
        certificateAddress: '',
        registryAddress: '',
        identityMethod: 'create',
        expiryDate: '',
        passportId: '',
        sampleDate: dateToday(),
        sampleTime: formatTime(roundDateTime()),
        testKitId: '',
        extraData: '',
        dataToEncrypt: '',
      }}
      validationSchema={Yup.object({
        expiryDate: Yup.string(),
        passportId: Yup.string(),
        sampleDate: Yup.string().required('This field is required'),
        sampleTime: Yup.string().test('not empty', gettext('This field is required'), (time) => !!time),
        testKitId: Yup.string().required('This field is required'),
        certificateAddress: Yup.string().required('This field is required'),
        registryAddress: Yup.string(),
        extraData: Yup.string(),
        dataToEncrypt: Yup.string(),
      })}
      onSubmit={onSubmit}
    >
      <IssueCertificateFormComponent
        companyId={companyId}
        passportId={passportId}
        setPassportId={setPassportId}
        pepper={pepper}
        setPepper={setPepper}
      />
    </Formik>
  );
};


IssueCertificateFormComponent.defaultProps = {
  companyId: null,
};

IssueCertificateFormComponent.propTypes = {
  companyId: PropTypes.string,
  passportId: PropTypes.string.isRequired,
  pepper: PropTypes.string.isRequired,
  setPassportId: PropTypes.func.isRequired,
  setPepper: PropTypes.func.isRequired,
};

IssueCertificateForm.defaultProps = {
  companyId: null,
};

IssueCertificateForm.propTypes = {
  companyId: PropTypes.string,
  issuerAddress: PropTypes.string.isRequired,
};


export default IssueCertificateForm;
